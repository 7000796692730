<!-- eslint-disable -->
<script setup>
import { ref } from 'vue'
import BorderLine from './BorderLine.vue'
import WeatherForecastDay from './WeatherForecastDay.vue'
import WeatherInfo from './WeatherInfo.vue'

defineProps({
    place: Object
})

const emit = defineEmits(['delete-place'])

const showDetail = ref(false)

const removePlace = (placeName) => {
    emit('delete-place', placeName)
    showDetail.value = false
}
</script>
<!-- eslint-disable -->
<template>
    <div :class="place.current.is_day === 1 ? 'bg-day' : 'bg-night'"
        class="text-white relative overflow-hidden  card-weather">
        <!-- Location & time -->
        <div class="mb-2 d-flex justify-content-between align-items-center">
            <div class="d-flex align-items-center justify-content-center">
                <div>
                    <h5 class="text-start">{{ place.location.name }}</h5>
                    <h5 class="text-start">{{ place.location.country }}</h5>
                </div>
            </div>
            <div class="d-flex align-items-center justify-content-center">
                <div>
                    <h5 class="text-end">{{ place.location.tz_id }}</h5>

                    <h5 class="text-end">
                        {{ $dayjs(place.location.localtime)?.utc().format("DD/MM/YYYY HH:mm:ss") }}
                    </h5>

                </div>
            </div>
        </div>

        <!-- current weather -->
        <div class="text-center d-flex-1">
            <img :src="place.current.condition.icon" alt="icon" width="70" class="mx-auto mb-10" />
            <h1 class="text-9xl mb-2 -mr-4">{{ place.current.temp_c }}&deg;</h1>
            <p class="text-2xl">{{ place.current.condition.text }}</p>

            <div class="geolocation latlong">
                <span>Lat : {{ place.location.lat }}</span> |
                <span>Lon : {{ place.location.lon }}</span>
            </div>
        </div>

        <BorderLine />

        <!-- forecast -->
        <div class="text-center mb-3">
            <h6>Prévisions sur 7 jours</h6>
        </div>
        <div v-for="(day, idx) in place.forecast.forecastday" :key="idx">
            <WeatherForecastDay :day="day" />
        </div>

        <!-- info -->
        <!-- <Transition name="fade">
            <div v-show="showDetail">
                <WeatherInfo :place="place" @close-info="showDetail = false"
                    @remove-place="removePlace(place.location.name)" />
            </div>
        </Transition> -->

        <!-- forecast btn -->
        <!-- <div class="d-flex justify-end align-items-center gap-1 mt-10">
            <button @click="showDetail = true">
                More <i class="fa-solid fa-arrow-right text-sm -mb-px"></i>
            </button>
        </div> -->
    </div>
</template>
<!-- eslint-disable -->
<style scoped>
.bg-day {
    background-color: #459ef7;
    background-image: linear-gradient(62deg, #459ef7 0%, #459ef7 100%);
}

.bg-night {
    background-color: #07223d;
    background-image: linear-gradient(62deg, #0a2a4a 0%, #270845 100%);
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.2s ease;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}

.card-weather {
    padding: 20px;
}
</style>