<!-- eslint-disable -->
<script setup>
import subscriptionsExcel from "@/components/export/subscriptions/excel.vue"; 
</script>
<!-- eslint-disable -->
<template>
  <div class="d-flex align-items-center justify-content-center" v-if="show">
    <div class="row w-100 mx-0 auth-page">
      <div class="col-md-12 mb-3">
        <div class="noble-ui-logo d-block mb-2">
          Liste<span>&nbsp;des abonnements utiliser csi code</span>
        </div>
      </div>

      <div class="col-md-12">
        <div class=" export-btn grid-3 mb-3 text-end">

          <span v-if="subscriptions.length" type="button" class="col-btn bg-white border-1-solid text-center">
            <subscriptionsExcel :exportTo="subscriptions" :type="`csi`" :filename="`CODE CSI`" />
          </span>

        </div>
      </div>
      <div v-if="subscriptions?.length > 0" class="col-md-12 col-xl-12 mx-auto">

        <DataTable showGridlines stripedRows :value="subscriptions">
          <Column field="LibSite" header="LibSite" sortable>
            <template #body="slotProps">
              <span class="LibSite" v-if="slotProps.data?.StoreID" @click="voirInfo(slotProps.data?.StoreID)">
                {{ slotProps.data?.LibSite }}
              </span>
              <span v-else>
                Old-version
              </span>

            </template>
          </Column>
          <Column field="Code CSI" header="Code CSI" sortable>
            <template #body="slotProps">
              {{ slotProps.data?.codecsi }}
            </template>
          </Column>
          <Column field="nbMonths" header="Nombre des mois" sortable>
            <template #body="slotProps">
              {{ slotProps.data.nbMonths }} Mois
            </template>
          </Column>
          <Column field="dateDebut" header="date Debut" sortable>
            <template #body="slotProps">
              {{ $dayjs(slotProps.data?.dateDebut)?.utc().format("DD/MM/YYYY HH:mm:ss") }}
            </template>
          </Column>
          <Column field="dateFin" header="date Fin" sortable>
            <template #body="slotProps">
              {{ $dayjs(slotProps.data?.dateFin)?.utc().format("DD/MM/YYYY HH:mm:ss") }}
            </template>
          </Column>
          <Column field="isActive" header="Status" sortable>
            <template #body="slotProps">
              <span v-if="slotProps.data?.isActive" class="table-tag" :style="{ backgroundColor: '#0be80b' }">
                Active
              </span>
              <span v-else class="table-tag" :style="{ backgroundColor: '#fb6060' }">
                Subscription expired
              </span>
            </template>
          </Column>



        </DataTable>



      </div>
    </div>
  </div>
</template>
<!-- eslint-disable -->
<script>
//import { DeleteIcon, EditIcon, MessageSquareIcon } from "@vue-icons/feather";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "ManagersView",
  components: {
    //DeleteIcon,
    //EditIcon,
    //MessageSquareIcon,
  },
  computed: {
    ...mapGetters({ isRole: "isRole", isAuthenticated: "isAuthenticated", User: "StateUser", BaseUrl: "StateBase" }),
  },
  mounted() {
    const get = async () => {
      try {
        this.subscriptions = await this.restaurantSubscribeWithCsiCode({ type: "codecsi" });

        this.show = true;
      } catch (error) {
        if (error) {
          this.show = false;
          console.log(error);
          this.showAlert("Forbidden resource");
        }
      }
    };
    if (this.isAuthenticated && this.$store.state.roles.includes(this.isRole))
      get();
  },
  data() {
    return {
      users: [],
      show: false,
      searchKeyword: "",
      filteredData: [],
      isAbonnementExiste: 'null',
      subscriptions: null,
    };
  },
  methods: {
    ...mapActions([
      "restaurantSubscribeWithCsiCode"
    ]),
    showAlert(error) {
      this.$swal({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        icon: "error",
        title: error,
        timer: 2000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
    },
    async voirInfo(store_id) {
      try {
        this.$router.push({
          name: "details-restaurant",
          params: { id: store_id },
        });
      } catch (error) {
        this.showAlert("nothing to delete");
      }
    },
  },
};
</script>