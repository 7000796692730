<!-- eslint-disable -->
<script setup>
import statistiquesPDF from "@/components/ToPDF/Statistiques/IndexPdf.vue";
import meilleursVentesPDF from "@/components/ToPDF/meilleursVentes/IndexPdf.vue";
import ventesParVendeurPDF from "@/components/ToPDF/ventesParVendeur/IndexPdf.vue";
import CardSearch from "@/components/CardSearch.vue";
import pointeusePdf from "@/components/ToPDF/pointeuse/pointeusePdf.vue";
import durationPdf from "@/components/ToPDF/pointeuse/durationPdf.vue";

</script>
<!-- eslint-disable -->
<template>
  <CardSearch v-if="currentSite" @search="getListPointeuses" :operation="false" :etat="false" :caisse="false"
    :date="true" :vendeur="true" :vendeurStaus="`all`" />

  <div class="row">
    <div class="col-md-12 grid-margin stretch-card">
      <div class="card">
        <div class="card-header">

          <ul class="nav nav-tabs" id="myTab" role="tablist">
            <li class="nav-item" role="presentation">
              <button class="nav-link active" id="details-pointeuse-tab" data-bs-toggle="tab"
                data-bs-target="#list-pointeuse" type="button" role="tab" aria-controls="list-pointeuse"
                aria-selected="true">
                <h3 class="card-title text-left">List des pointeuses</h3>
              </button>
            </li>
            <li class="nav-item" role="presentation">
              <button class="nav-link" id="total-durations-tab" data-bs-toggle="tab" data-bs-target="#total-durations"
                type="button" role="tab" aria-controls="total-durations" aria-selected="false">
                <h3 class="card-title text-left">Cumul par vendeur</h3>

              </button>
            </li>
          </ul>
        </div>
        <div class="tab-content" id="myTabContent">
          <div class="tab-pane fade show active" id="list-pointeuse" role="tabpanel"
            aria-labelledby="details-pointeuse-tab">

            <div v-if="data.pointeuses && data.pointeuses.length > 0" class="card-body">
              <div class="row mb-5">
                <div class="col-md-12">
                  <pointeusePdf :exportTo="exportTo.pointeuses" :dateFrom="datePicker.date.from"
                    :dateTo="datePicker.date.to" />
                </div>
              </div>
              <div class="table-responsive tableClasss">

                <DataTable showGridlines stripedRows :value="data.pointeuses">
                  <!-- <Column field="LibSite" header="Site" sortable> </Column> -->
                  <Column field="LibVendeur" header="Nom" sortable> </Column>
                  <Column field="date" header="Date" sortable>
                    <template #body="slotProps">
                      <span>
                        {{ $dayjs(slotProps?.data?.date)?.utc().format("DD/MM/YYYY") }}
                      </span>
                    </template>
                  </Column>
                  <Column field="timeStartWork" header="Heure d'arrivee" sortable>
                    <template #body="slotProps">
                      <span>
                        {{ slotProps?.data?.timeStartWork }}
                      </span>
                    </template>
                  </Column>
                  <Column field="timeEndWork" header="Heure de depart" sortable>
                    <template #body="slotProps">
                      <span>
                        {{ slotProps?.data?.timeEndWork }}
                      </span>
                    </template>
                  </Column>
                  <Column field="duration" header="durée" sortable>
                    <template #body="slotProps">
                      <span>
                        {{ slotProps?.data?.duration }}
                      </span>
                    </template>
                  </Column>
                  <Column field="timeEndWork" header="Etat" sortable>
                    <template #body="slotProps">
                      <span v-if="!slotProps?.data?.timeEndWork" class="table-tag"
                        :style="{ backgroundColor: '#0be80b', color: '#000' }">
                        Encore en train de travailler
                      </span>
                      <span v-else class="table-tag" :style="{ backgroundColor: '#fb6060', color: '#fff' }">
                        Départ
                      </span>
                    </template>
                  </Column>

                </DataTable>


              </div>
            </div>
            <div v-else class="card-body d-flex align-items-center justify-content-center">
              <datanotfound :text="$t(`content.message.datanotfound`)" />
            </div>
          </div>
          <div class="tab-pane fade" id="total-durations" role="tabpanel" aria-labelledby="total-durations-tab">


            <div v-if="data.totalDurations && data.totalDurations.length > 0" class="card-body">

              <div class="row mb-5">
                <div class="col-md-12">
                  <durationPdf :exportTo="exportTo.totalDurations" :dateFrom="datePicker.date.from"
                    :dateTo="datePicker.date.to" />
                </div>
              </div>

              <div class="table-responsive tableClasss">

                <DataTable showGridlines stripedRows :value="data.totalDurations">
                  <Column field="LibVendeur" header="Nom" sortable> </Column>


                  <Column field="totalDuration" header="durée" sortable>
                    <template #body="slotProps">
                      <span>
                        {{ slotProps?.data?.totalDuration }}
                      </span>
                    </template>
                  </Column>

                </DataTable>


              </div>
            </div>
            <div v-else class="card-body d-flex align-items-center justify-content-center">
              <datanotfound :text="$t(`content.message.datanotfound`)" />
            </div>
          </div>
        </div>




      </div>
    </div>
  </div>
</template>
<!-- eslint-disable -->
<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";

import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import { alertSwal } from "@/Utils/globale.js";

export default {
  components: { VueDatePicker, statistiquesPDF, meilleursVentesPDF, ventesParVendeurPDF },


  data() {
    return {
      exportTo: {
        vendeurs: {
          columns: [],
          data: [],
          fileName: "",
        },
        pointeuses: {
          columns: [],
          data: [],
          fileName: "Pointeuse",
        },
        totalDurations: {
          columns: [],
          data: [],
          fileName: "Cumul par vendeur",
        },
      },
      data: {
        pointeuses: [],
        totalDurations: [],

      },
      pagination: {
        page: 0,
        limit: 4,
        skip: 0,
        per_page: 10,
        data: [],
      },
      searchs: {
        vendeurs: ["vendeur"],
      },
    };
  },
  mounted() {
    const get = async () => {
      this.getListPointeuses();
    };
    if (this.isAuthenticated && this.isRole == 'manager')
      get();
  },
  created() {

  },
  methods: {
    ...mapMutations(["setTicketID"]),
    ...mapActions([
      "fetchPointeuses",
      "fetchTotalDurations"
    ]),
    // callFunctions() {
    //   if (this.currentSite?._id) {
    //     let range = this.datePicker.defaultDates.type ? this.datePicker.defaultDates.type : this.datePicker.date.from
    //     this.getListPointeuses(range, this.datePicker.date.to);

    //   }
    // },
    async getListPointeuses() {
      this.data.pointeuses = await this.fetchPointeuses({
        url: "pointeuses",
        range: this.datePicker.date.range,
        site: this.currentSite?._id,
        WhereLibInArray: [
          { LibValeur: this.globalSearch?.SelectedLibVendeurs?.length ? this.globalSearch?.SelectedLibVendeurs : 'vendeur', LibKey: 'LibVendeur' },
          { LibValeur: this.globalSearch?.SelectedLibCaisses?.length ? this.globalSearch?.SelectedLibCaisses : 'caisse', LibKey: 'LibCaisse' }
        ]
      });

      this.exportTo.pointeuses.data = [];
      this.exportTo.pointeuses.data = this.data.pointeuses || [];
      if (this.data.pointeuses.length > 0) {
        this.exportTo.pointeuses.columns = [];
        this.exportTo.pointeuses.columns.push("Vendeur");
        this.exportTo.pointeuses.columns.push("Date");
        this.exportTo.pointeuses.columns.push("Heure d'arrivee");
        this.exportTo.pointeuses.columns.push("Heure de départ");
        this.exportTo.pointeuses.columns.push("Durée");
      }


      this.getListTotalDurations()
    },

    async getListTotalDurations() {
      this.data.totalDurations = await this.fetchTotalDurations({
        url: "pointeuses/total-durations",
        range: this.datePicker.date.range,
        site: this.currentSite?._id,
        WhereLibInArray: [
          { LibValeur: this.globalSearch?.SelectedLibVendeurs?.length ? this.globalSearch?.SelectedLibVendeurs : 'vendeur', LibKey: 'LibVendeur' },
          { LibValeur: this.globalSearch?.SelectedLibCaisses?.length ? this.globalSearch?.SelectedLibCaisses : 'caisse', LibKey: 'LibCaisse' }
        ]
      });
      this.exportTo.totalDurations.data = [];
      this.exportTo.totalDurations.data = this.data.totalDurations || [];
      if (this.data.totalDurations.length > 0) {
        this.exportTo.totalDurations.columns = [];
        this.exportTo.totalDurations.columns.push("LibVendeur");
        this.exportTo.totalDurations.columns.push("Durée");
      }


    },

  },
  computed: {
    ...mapGetters({ isRole: "isRole", isAuthenticated: "isAuthenticated", res: "StateRes", currentSite: "currentSite" }),
    ...mapState(["datePicker", "globalSearch"]),
  },
};
</script>
<!-- eslint-disable -->
<style>
.selected-default-dates {
  background: #084593 !important;
  color: #fff !important;
}
</style>