<!-- eslint-disable -->
<template>
  <div class="row">
    <div class="col-md-12">
      <div class="mt-2 mb-4 ">

        <div class="noble-ui-logo d-block mb-2"> Chiffre d'affaires total pour tous <span>&nbsp;les
            restaurants</span></div>
      </div>
    </div>
  </div>
  <div class="card mb-3">
    <div class="card-body">

      <div v-if="listChiffreAffaires?.length > 0" class="row">
        <div class="col-md-12 type_ca grid-8 mb-3">
          <span type="button" class="btn btn-info btn-icon-text  " @click="filterData('today', true)"
            :class="{ 'selected-default-dates': datePicker.defaultDates.type == 'today' }">

            {{ $t(`content.button.today`) }}
            <p class="chiffre-afaire">
              {{
                listChiffreAffaires[0][0]
                  ? listChiffreAffaires[0][0]["Chiffre d'affaires (CA)"].toFixed(2)
                  : "0.00"
              }}
              €</p>
          </span>

          <span type="button" class="btn btn-info btn-icon-text  " @click="filterData('yesterday', true)"
            :class="{ 'selected-default-dates': datePicker.defaultDates.type == 'yesterday' }">

            {{ $t(`content.button.yesterday`) }}
            <p class="chiffre-afaire">
              {{
                listChiffreAffaires[1][0]
                  ? listChiffreAffaires[1][0]["Chiffre d'affaires (CA)"].toFixed(2)
                  : "0.00"
              }}
              €</p>
          </span>

          <span type="button" class="btn btn-info btn-icon-text  " @click="filterData('week', true)"
            :class="{ 'selected-default-dates': datePicker.defaultDates.type == 'week' }">

            {{ $t(`content.button.week`) }}
            <p class="chiffre-afaire">
              {{
                listChiffreAffaires[2][0]
                  ? listChiffreAffaires[2][0]["Chiffre d'affaires (CA)"].toFixed(2)
                  : "0.00"
              }}
              €</p>
          </span>

          <span type="button" class="btn btn-info btn-icon-text  " @click="filterData('previous_week', true)"
            :class="{ 'selected-default-dates': datePicker.defaultDates.type == 'previous_week' }">

            {{ $t(`content.button.previous_week`) }}
            <p class="chiffre-afaire">
              {{
                listChiffreAffaires[3][0]
                  ? listChiffreAffaires[3][0]["Chiffre d'affaires (CA)"].toFixed(2)
                  : "0.00"
              }} €</p>
          </span>

          <span type="button" class="btn btn-info btn-icon-text  " @click="filterData('month', true)"
            :class="{ 'selected-default-dates': datePicker.defaultDates.type == 'month' }">

            {{ $t(`content.button.month`) }}
            <p class="chiffre-afaire">
              {{
                listChiffreAffaires[4][0]
                  ? listChiffreAffaires[4][0]["Chiffre d'affaires (CA)"].toFixed(2)
                  : "0.00"
              }}
              €</p>
          </span>
          <span type="button" class="btn btn-info btn-icon-text  " @click="filterData('previous_month', true)"
            :class="{ 'selected-default-dates': datePicker.defaultDates.type == 'previous_month' }">

            {{ $t(`content.button.previous_month`) }}
            <p class="chiffre-afaire"> {{
              listChiffreAffaires[5][0]
                ? listChiffreAffaires[5][0]["Chiffre d'affaires (CA)"].toFixed(2)
                : "0.00"
            }} €</p>
          </span>
          <span type="button" class="btn btn-info btn-icon-text  " @click="filterData('year', true)"
            :class="{ 'selected-default-dates': datePicker.defaultDates.type == 'year' }">

            {{ $t(`content.button.year`) }}
            <p class="chiffre-afaire">
              {{
                listChiffreAffaires[6][0]
                  ? listChiffreAffaires[6][0]["Chiffre d'affaires (CA)"].toFixed(2)
                  : "0.00"
              }} €</p>
          </span>
          <span type="button" class="btn btn-info btn-icon-text  " @click="filterData('previous_year', true)"
            :class="{ 'selected-default-dates': datePicker.defaultDates.type == 'previous_year' }">

            {{ $t(`content.button.previous_year`) }}
            <p class="chiffre-afaire">
              {{
                listChiffreAffaires[7][0]
                  ? listChiffreAffaires[7][0]["Chiffre d'affaires (CA)"].toFixed(2)
                  : "0.00"
              }} €</p>
          </span>

        </div>
        <div class="col-md-12 mb-3 d-flex">
          <div class="w-50 p-1">
            <label for="Date" class="form-label">Dates</label>
            <VueDatePicker locale="fr" @update:model-value="handleDate" v-model="selectedDates"
              :action-row="{ showSelect: false, showCancel: false }" range multiCalendars :enableTimePicker="true"
              format="dd-MM-yyyy HH:mm" close-on-scroll auto-apply show-now-button />
          </div>
          <div v-if="$route.name === 'dashboard'" class="w-50 p-1">
            <label for="date-1" class="form-label">Date N-1</label>
            <VueDatePicker locale="fr" v-model="selectedDatesN1" disabled
              :action-row="{ showSelect: false, showCancel: false }" range multiCalendars :enableTimePicker="true"
              format="dd-MM-yyyy HH:mm" close-on-scroll auto-apply show-now-button />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <div class="mt-2 mb-4 ">

        <div class="noble-ui-logo d-block mb-2"> Chiffre d'affaires total par <span>&nbsp;restaurant</span></div>
      </div>
    </div>
  </div>
  <div v-if="dashboard?.stat_principal?.data" class="row mb-3">
    <div v-for="(stats, index) in dashboard?.stat_principal?.data"
      class="col-xl-6 col-lg-6 mb-3 d-flex align-items-stretch card-100">
      <div class="card card-stats mb-4 mb-xl-0">
        <div class="card-body">
          <div
            class="statistique statistique-font-size font-weight-bold mb-0 d-flex height-100 sjustify-content-center align-items-center">
            <div class="w-100">
              <div class="text-centers text-uppercase font-size-20">
                <div>

                  {{ $t("content.pages.dashboard.cards.couvert_n.couvert") }} :
                  <span class="float-end">
                    {{
                      stats?.entete
                        ? stats?.entete?.totalCouvertTable
                        : "0"
                    }}
                  </span>
                </div>
              </div>
              <div class="text-centers text-uppercase font-size-20 mt-2">
                <div>

                  {{ $t("content.pages.dashboard.cards.couvert_n.couvert_moyen") }}:
                  <span class="float-end">
                    {{
                      stats?.entete
                        ? stats?.entete?.totalCouvertMoyenneTable?.toFixed(2)
                        : "0.00"
                    }}
                    €
                  </span>
                </div>
              </div>
              <div class="text-centers text-uppercase font-size-20 mt-2">
                <div>

                  {{ $t("content.pages.dashboard.cards.couvert_n.ca_table") }} :
                  <span class="float-end">
                    {{
                      stats?.entete
                        ? stats?.entete?.totalChiffreCATable.toFixed(
                          2
                        )
                        : "0.00"
                    }}
                    €
                  </span>
                </div>
              </div>
              <div class="text-centers text-uppercase font-size-20 mt-2">
                <div>

                  {{ $t("content.pages.dashboard.cards.couvert_n.ca") }} :
                  <span class="float-end">
                    {{
                      stats?.entete
                        ? stats?.entete?.totalChiffreCA.toFixed(
                          2
                        )
                        : "0.00"
                    }}
                    €
                  </span>
                </div>
              </div>
              <div class="text-centers text-uppercase font-size-20 mt-2">
                <div>

                  {{ $t("content.pages.dashboard.cards.couvert_n.reglements") }} :
                  <span class="float-end">
                    {{
                      stats?.reglements
                        ? stats?.reglements?.TotalValeur.toFixed(2)
                        : "0.00"
                    }}
                    €
                  </span>
                </div>
              </div>
              <div class="text-center text-uppercase hr-border">
                <h3 v-if="stats?.site">
                  {{ stats?.site?.LibSite }}
                </h3>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<!-- eslint-disable -->
<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import LineChart from "@/components/chartVue/ChartLine.vue";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";

import { DateTime } from "luxon";


export default {
  components: { LineChart, VueDatePicker },
  name: "mongoDATA",
  watch: {
    "datePicker.date.range"(newDate, oldDate) {
      if (newDate !== oldDate)
        this.callFunctions(
          this.datePicker.date.from,
          this.datePicker.date.to,
          this.datePicker.defaultDates.type
        );
    },
  },
  data() {
    return {
      all_sites: [],
      selectedDates: [],
      userLocation: null,
      weatherData: null,
      city: null,
      statistiques_mongoDB: [],
      meilleurventes_mongoDB: [],
      reglements_mongoDB: [],
      stat_principal_mongoDB: [],
      encaissement_mongoDB: [],
      dashboard: {
        revenu_statistques: [],
        stat_principal: [],
        chiffre_affaires_total: [],
        chiffre_affaires_par_caisse: [],
        total_reglements: [],
        total_reglements_n_1: [],
        list_reglements_groupby_famille: [],
      },

      chiffre_affaire_total: 0,

      per_page: 10,

      interval: null,
    };
  },
  async mounted() {
    if (this.isAuthenticated && this.isRole == 'manager') {
      if (this.all_sites?.length > 0) {
        if (this.datePicker.date.range?.length > 0) {
          this.selectedDates = this.datePicker.date.range
        }

        this.getChffireAffaires();


        this.callFunctions(
          this.datePicker.date.from,
          this.datePicker.date.to,
          this.datePicker.defaultDates.type
        );
      }
    }
  },
  async created() {
    this.all_sites = this.$store.state.managerWithStores?.associatedRestaurants.map(site => site._id)

  },
  unmounted() {
  },
  methods: {
    ...mapMutations(["setTicketID"]),
    ...mapActions([
      "BoardStatAllSites",
      "chiffreAffaires"
    ]),


    callFunctions(from, to, type) {
      if (this.currentSite?._id) {
        let range = from
        this.getStatPrincipal(range, to);
      }
    },




    async getStatPrincipal(from, to) {
      this.dashboard.stat_principal = []
      let qte = "qte";
      let page = 1;
      let limit = 0;
      let self = this;
      this.dashboard.stat_principal = await this.BoardStatAllSites({
        url: "board/stat-all-sites",
        page: page,
        limit: limit,
        range: self.datePicker.date.range,
        vendeur: "vendeur",
        site: self.all_sites,
        qte: qte,
        WhereLibInArray: [
          { LibValeur: 'vendeur', LibKey: 'LibVendeur' },
          { LibValeur: 'caisse', LibKey: 'LibCaisse' },
          { LibValeur: ['VENTE', 'TRANSFERT', 'ANNULATION'], LibKey: 'Operation' },
        ]
      });
    },


    filterData(option, selectOption) {

      const timeZone = 'Europe/Paris';
      const currentDate = DateTime.now().setZone(timeZone).setZone('UTC+0');
      let dateFrom, dateTo;
      let dateNFrom, dateNTo;

      const [fromHour, fromMinutes] = this.parametrages?.calculeTicket?.TimeDebut?.split(':') ?? ['00', '00']
      const [toHour, toMinute] = this.parametrages?.calculeTicket?.TimeFin?.split(':') ?? ['23', '59']

      const startTime = { hour: fromHour, minute: fromMinutes }
      const endTime = { hour: toHour, minute: toMinute }

      switch (option) {
        case 'today':
          dateFrom = currentDate.set(startTime);
          dateTo = dateFrom.set(endTime);

          dateNFrom = currentDate.minus({ year: 1 }).set(startTime);
          dateNTo = dateNFrom.set(endTime);


          break;
        case "yesterday":
          dateFrom = currentDate.set(startTime).minus({ day: 1 });
          dateTo = dateFrom.set(endTime);

          dateNFrom = currentDate.minus({ year: 1 }).set(startTime).minus({ day: 1 });
          dateNTo = dateNFrom.set(endTime);


          break;
        case 'week':

          // Start from Monday (weekday 1) and end on Sunday (weekday 7)
          dateFrom = currentDate.startOf('week').set(startTime);
          dateTo = currentDate.endOf('week').minus({ days: 1 }).set(startTime);
          dateNFrom = dateFrom.minus({ years: 1 }); //.startOf('week').set(startTime);
          dateNTo = dateTo.minus({ years: 1 }); //.endOf('week').set(startTime);
          break;
        case 'previous_week':

          // Start from Monday of the previous week and end on Sunday of the previous week
          const startOfPreviousWeek = currentDate.startOf('week').set(startTime).minus({ weeks: 1 });
          const endOfPreviousWeek = currentDate.endOf('week').set(endTime).minus({ weeks: 1, days: 1 });
          dateFrom = startOfPreviousWeek;
          dateTo = endOfPreviousWeek;

          dateNFrom = dateFrom.minus({ years: 1 });
          dateNTo = dateTo.minus({ years: 1 });
          break;

        case 'month':

          dateFrom = currentDate.startOf('month').set(startTime);
          dateTo = currentDate.endOf('month').set(endTime);

          dateNFrom = dateFrom.minus({ years: 1 });
          dateNTo = dateTo.minus({ years: 1 });


          break;
        case 'previous_month':
          dateFrom = currentDate.startOf('month').set(startTime).minus({ months: 1 });
          dateTo = currentDate.endOf('month').set(endTime).minus({ months: 1 });

          dateNFrom = dateFrom.minus({ years: 1 });
          dateNTo = dateTo.minus({ years: 1 });



          break;
        case 'year':
          dateFrom = currentDate.startOf('year').set(startTime);
          dateTo = currentDate.endOf('year').set(endTime);

          dateNFrom = dateFrom.minus({ years: 1 });
          dateNTo = dateTo.minus({ years: 1 });


          break;
        case 'previous_year':

          dateFrom = currentDate.startOf('year').set(startTime).minus({ years: 1 });
          dateTo = currentDate.endOf('year').set(endTime).minus({ years: 1 });

          dateNFrom = dateFrom.minus({ years: 1 });
          dateNTo = dateTo.minus({ years: 1 });


          break;
      }

      if (fromHour > 0) {
        dateTo = dateTo.plus({ days: 1 })
        dateNTo = dateNTo.plus({ days: 1 })
      }

      const isodateFrom = DateTime.fromISO(dateFrom);
      const isodateTo = DateTime.fromISO(dateTo);
      const formattedDateFrom = isodateFrom.toISO({ includeOffset: false, suppressMilliseconds: false });
      const formattedDateTo = isodateTo.toISO({ includeOffset: false, suppressMilliseconds: false });

      const isodateNFrom = DateTime.fromISO(dateNFrom);
      const isodateNTo = DateTime.fromISO(dateNTo);
      const formattedNDateFrom = isodateNFrom.toISO({ includeOffset: false, suppressMilliseconds: false });
      const formattedNDateTo = isodateNTo.toISO({ includeOffset: false, suppressMilliseconds: false });

      if (selectOption) {
        this.datePicker.date_n.range = [formattedNDateFrom, formattedNDateTo];
        this.datePicker.date.range = [formattedDateFrom, formattedDateTo];
        this.datePicker.date.from = dateFrom.toFormat('MM-dd-yyyy HH:mm');
        this.datePicker.date.to = dateTo.toFormat('MM-dd-yyyy HH:mm');
        this.datePicker.date_n.from = dateNFrom.toFormat('MM-dd-yyyy HH:mm');
        this.datePicker.date_n.to = dateNTo.toFormat('MM-dd-yyyy HH:mm');
        this.selectedDates = [this.datePicker.date.from, this.datePicker.date.to];
        this.selectedDatesN1 = [this.datePicker.date_n.from, this.datePicker.date_n.to];
        this.datePicker.defaultDates.type = option
      }


      if (!selectOption)
        return [formattedDateFrom, formattedDateTo]
    },
    handleDate(selectedDate) {
      let dateFrom, dateTo;
      let dateNFrom, dateNTo;

      const [fromHour, fromMinutes] = this.parametrages?.calculeTicket?.TimeDebut?.split(':') ?? ['00', '00']
      const [toHour, toMinute] = this.parametrages?.calculeTicket?.TimeFin?.split(':') ?? ['23', '59']


      const from = (this.$dayjs(selectedDate[0]).format('YYYY-MM-DD HH:mm'))
      dateFrom = DateTime.fromFormat(from, 'yyyy-MM-dd HH:mm');
      const to = (this.$dayjs(selectedDate[1]).format('YYYY-MM-DD HH:mm'))
      dateTo = DateTime.fromFormat(to, 'yyyy-MM-dd HH:mm');

      dateNFrom = dateFrom.minus({ year: 1 })
      dateNTo = dateTo.minus({ year: 1 })

      if (fromHour > 0) {
        dateTo = dateTo.plus({ day: 1 })
        dateNTo = dateNTo.plus({ day: 1 })
      }
      this.datePicker.date.from = dateFrom.toFormat('MM-dd-yyyy HH:mm');
      this.datePicker.date.to = dateTo.toFormat('MM-dd-yyyy HH:mm');


      this.datePicker.date_n.from = dateNFrom.toFormat('MM-dd-yyyy HH:mm');
      this.datePicker.date_n.to = dateNTo.toFormat('MM-dd-yyyy HH:mm');

      const isodateFrom = DateTime.fromISO(dateFrom);
      const isodateTo = DateTime.fromISO(dateTo);
      const formattedDateFrom = isodateFrom.toISO({ includeOffset: false, suppressMilliseconds: false });
      const formattedDateTo = isodateTo.toISO({ includeOffset: false, suppressMilliseconds: false });
      this.datePicker.date.range = [formattedDateFrom, formattedDateTo];

      const isodateNFrom = DateTime.fromISO(dateNFrom);
      const isodateNTo = DateTime.fromISO(dateNTo);
      const formattedNDateFrom = isodateNFrom.toISO({ includeOffset: false, suppressMilliseconds: false });
      const formattedNDateTo = isodateNTo.toISO({ includeOffset: false, suppressMilliseconds: false });
      this.datePicker.date_n.range = [formattedNDateFrom, formattedNDateTo];
      this.datePicker.defaultDates.type = null
      //  this.selectedDates = [this.datePicker.date.from, this.datePicker.date.to];
      this.selectedDatesN1 = [this.datePicker.date_n.from, this.datePicker.date_n.to];
    },
    async getChffireAffaires() {
      const dates = ['today', 'yesterday', 'week', 'previous_week', 'month', 'previous_month', 'year', 'previous_year']
      let self = this
      this.datePicker.defaultDates.list = []
      dates.forEach((element) => {

        this.datePicker.defaultDates.list.push(self.filterData(element, false))
      });
      this.chiffre_affaires = [];
      this.chiffre_affaires = await this.chiffreAffaires({
        url: "entetes/chiffre-affaire",
        vendeur: "vendeur",
        site: self.all_sites,
        rangeDates: this.datePicker.defaultDates.list
      });
    },
  },
  computed: {
    ...mapGetters({
      isAuthenticated: "isAuthenticated",
      isRole: "isRole",
      res: "StateRes",
      currentSite: "currentSite",
      listChiffreAffaires: "listChiffreAffaires",
      TimeDebutCalclule: "TimeDebutCalclule"
    }),
    ...mapState(["ticket_id", "datePicker", 'chiffre_affaires']),
    // return this.$store.state.user;
    chiffredaffaires() {
      if (this.chiffre_affaires.length > 0) {
        return this.chiffre_affaires;
      }
      return [];
    },

  },
};
</script>
<!-- eslint-disable -->

<style>
.selected-default-dates {
  background: #084593 !important;
  color: #fff !important;
}

.card-100 .card {
  width: 100%;
}

.card .card-title {
  margin-bottom: 0px !important;
}



.type_ca span.brut {
  border-left: 1px solid #d9d9d9;
  border-right: 0px solid #d9d9d9;
  border-radius: 2px 0 0 2px;
}

.type_ca span.net {
  border-left: 0px solid #d9d9d9;
  border-right: 1px solid #d9d9d9;
  border-radius: 0 2px 2px 0;
}

.btn-event-export {
  border: 1px solid #bdbdbd;
  padding: 5px;
  text-align: center;
  background: #fff;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
}

.statistique-font-size {
  font-size: 20px;
}

.statistique {
  font-weight: 600;
  margin-top: 10px !important;
  display: block;
  margin: 0px !important;
}

.height-100 {
  height: 100%;
}

.geolocation.latlong {
  font-size: 15px;
}

.font-size-20 {
  font-size: 14px;
}

.card-body {
  padding: 0.8rem 0.8rem !important;
}


.percentage svg {
  margin-right: 4px;
}

.percentage .red {
  color: red !important;
}

.percentage .green,
.percentage {
  color: #14e114;
}

.percentage {
  width: 90px;
  display: inline-block;
}
</style>
