<!-- eslint-disable -->
<template>
    <div class="loader-y">
        <div class="loader-x"></div>
    </div>
</template>
<!-- eslint-disable -->
<script>
export default {

};
</script>
<!-- eslint-disable -->
<style scoped>
.loader-y {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #bdbdbd;
    opacity: 0.3;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99;


}

.loader-x {
    border: 8px solid #f3f3f3;
    /* Light grey */
    border-top: 8px solid #000865;
    /* Blue */
    border-radius: 50%;
    width: 45px;
    height: 45px;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
</style>