<!-- eslint-disable -->
<script setup>
import ticketPDF from "@/components/ToPDF/tickets/IndexPdf.vue";
import ticketExcel from "@/components/ToPDF/tickets/excel.vue";
import ticketToMail from "@/components/ToPDF/tickets/toEmail.vue";
</script>
<!-- eslint-disable -->
<template>
  <ticketToMail v-if="arr_commandes.length" :exportTo="exportTo.ticket" />

  <div class="row" v-if="ticket_mongoDB">
    <!-- <div class="col-md-8 mb-4">
      <div class="type_ca float-start">
        <router-link :to="{ name: 'tickets' }"><i class="fas fa-arrow-left"></i> Retour à la page précédente
        </router-link>
      </div>
    </div> -->
    <div class="col-md-12">
      <div class="row">
        <div class="col-md-12 export-btn grid-3 mb-3 text-end">
          <span v-if="arr_commandes.length" type="button" class="col-btn bg-white border-1-solid text-center">
            <ticketPDF :exportTo="exportTo.ticket" />
          </span>
          <span v-if="arr_commandes.length" type="button" class="col-btn bg-white border-1-solid text-center">
            <ticketExcel :exportTo="exportTo.ticket" />
          </span>
          <span v-if="arr_commandes.length" type="button" class="col-btn bg-white border-1-solid text-center ">
            <div data-bs-toggle="modal" data-bs-target="#modalSendMail"> <i class="far fa-envelope"></i> Envoyer
            </div>
          </span>
        </div>
      </div>
    </div>

    <div class="col-md-12 justify-content-between align-items-center flex-wrap grid-margin">
      <div class="card">
        <div class="card-body">
          <div class="row mt-2">
            <div v-if="ticket_mongoDB.LibSite" class="col-12 col-md-12 col-xl-12  mb-5">
              <div class="text-center">
                <h3>Site : {{ ticket_mongoDB.LibSite }}</h3>
              </div>
            </div>
            <div v-if="ticket_mongoDB.NumTicket" class="col-12 col-md-12 col-xl-12  mb-5">
              <div class="text-center">
                <h4>Numéro de ticket : {{ ticket_mongoDB.NumTicket }}</h4>
              </div>
            </div>
            <div class="col-12 col-md-12 col-xl-12">
              <div class="row">

                <div v-if="ticket_mongoDB.LibVendeur" class="col-md-3 mb-3">
                  <p class="mb-2 text-muteds info-restautant">
                    <b>Vendeur</b> : {{ ticket_mongoDB.LibVendeur }}
                  </p>
                </div>


                <div v-if="ticket_mongoDB.Operation" class="col-md-3 mb-3">
                  <p class="mb-2 text-muteds info-restautant">
                    <b>Opération</b> : {{ ticket_mongoDB.Operation }}
                  </p>
                </div>
                <div v-if="ticket_mongoDB.Etat" class="col-md-3 mb-3">
                  <p class="mb-2 text-muteds info-restautant">
                    <b>Etat</b> : {{ ticket_mongoDB.Etat }}
                  </p>
                </div>
                <div v-if="ticket_mongoDB.Horodatage" class="col-md-3 mb-3">
                  <p class="mb-2 text-muteds info-restautant">
                    <b>Date </b> :
                    {{ $dayjs(ticket_mongoDB.Horodatage).utc().format("DD/MM/YYYY HH:mm:ss") }}
                  </p>
                </div>

                <div v-if="ticket_mongoDB.EmetteurTelephone" class="col-md-3 mb-3">
                  <p class="mb-2 text-muteds info-restautant">
                    <b>Téléphone </b> : {{ ticket_mongoDB.EmetteurTelephone }}
                  </p>
                </div>

                <div v-if="ticket_mongoDB.LibCaisse" class="col-md-3 mb-3">
                  <p class="mb-2 text-muteds info-restautant">
                    <b>Caisse </b> : {{ ticket_mongoDB.LibCaisse }}
                  </p>
                </div>
                <div v-if="ticket_mongoDB.MontantHT" class="col-md-3 mb-3">
                  <p class="mb-2 text-muteds info-restautant">
                    <b>Montant HT</b> : {{ (ticket_mongoDB.MontantHT).toFixed(2).toString() }} €
                  </p>
                </div>
                <div v-if="ticket_mongoDB.MontantTVA" class="col-md-3 mb-3">
                  <p class="mb-2 text-muteds info-restautant">
                    <b>Montant TVA</b> : {{ (ticket_mongoDB.MontantTVA).toFixed(2).toString() }} €
                  </p>
                </div>
                <div v-if="ticket_mongoDB.MontantTTC" class="col-md-3 mb-3">
                  <p class="mb-2 text-muteds info-restautant">
                    <b>Montant TTC</b> : {{ (ticket_mongoDB.MontantTTC).toFixed(2).toString() }} €
                  </p>
                </div>
              </div>
            </div>


            <div v-if="transfertArticles?.length > 0" class="col-12 col-md-12 col-xl-12 mt-5 =">
              <div class="table-responsive ">
                <DataTable showGridlines stripedRows :value="transfertArticles">
                  <Column header="DESIGNATION" sortable>
                    <template #body="slotProps">
                      <span v-if="slotProps?.data?.OFFERT">
                        {{ Number(slotProps?.data?.OFFERT?.Quantite).toFixed(0) }}
                        {{ slotProps?.data?.Libelle }}
                        ({{ slotProps?.data?.OFFERT?.Libelle }})
                      </span>
                      <span v-else>
                        {{ Number(slotProps?.data?.QuantiteNet).toFixed(0) }}
                        {{ slotProps?.data?.Libelle }}
                        <span v-if="slotProps?.data?.ANNULATION">
                          ( {{ slotProps?.data?.ANNULATION?.Libelle }})
                        </span>
                      </span>
                    </template>
                  </Column>
                  <Column header="Prix UT" sortable>
                    <template #body="slotProps">
                      <span v-if="slotProps?.data?.OFFERT">
                        {{ Number(slotProps?.data?.OFFERT?.Valeur).toFixed(2).toString() }} €
                      </span>
                      <span v-else>
                        {{ Number(slotProps?.data?.PrixUnitaireTTC).toFixed(2).toString() }} €
                      </span>
                    </template>
                  </Column>
                  <Column header="Prix HT" sortable>
                    <template #body="slotProps">
                      <span v-if="slotProps?.data?.OFFERT">
                        {{ Number(slotProps?.data?.OFFERT?.Valeur).toFixed(2).toString() }} €
                      </span>
                      <span v-else>
                        {{ Number(slotProps?.data?.PrixTotalNetHT).toFixed(2).toString() }} €
                      </span>
                    </template>
                  </Column>
                  <Column header="Total" sortable>
                    <template #body="slotProps">
                      <span v-if="slotProps?.data?.OFFERT">
                        {{ Number(slotProps?.data?.OFFERT?.Valeur).toFixed(2).toString() }} €
                      </span>
                      <span v-else>
                        {{ Number(slotProps?.data?.PrixTotalNet).toFixed(2).toString() }} €
                      </span>
                    </template>
                  </Column>
                  <Column header="Total" sortable>
                    <template #body="slotProps">
                      <span>
                        {{ slotProps?.data?.LibTVA }}
                      </span>
                    </template>
                  </Column>

                </DataTable>

                <!-- <table class="table table-striped table-hovers caption-top">
                  <thead class="table-dark">
                    <tr class="">
                      <th>DESIGNATION</th>
                      <th class="text-end">Prix UT</th>
                      <th class="text-end">Prix HT</th>
                      <th class="text-end">Total</th>
                      <th class="text-end">TVA</th>
                    </tr>
                  </thead>
                  <tbody class="">
                    <tr v-for="(article, index_article) in transfertArticles" :key="index_article">
                      <td>
                        <span v-if="article?.OFFERT">
                          {{ Number(article?.OFFERT?.Quantite).toFixed(0) }}
                          {{ article.Libelle }}
                          ({{ article?.OFFERT?.Libelle }})
                        </span>
                        <span v-else>
                          {{ Number(article.QuantiteNet).toFixed(0) }}
                          {{ article.Libelle }}
                          <span v-if="article?.ANNULATION">
                            ( {{ article?.ANNULATION?.Libelle }})
                          </span>
                        </span>
                      </td>
                      <td class="text-end">
                        <span v-if="article?.OFFERT">
                          {{ Number(article?.OFFERT?.Valeur).toFixed(2).toString() }} €
                        </span>
                        <span v-else>
                          {{ Number(article.PrixUnitaireTTC).toFixed(2).toString() }} €
                        </span>
                      </td>
                      <td class="text-end">
                        <span v-if="article?.OFFERT">
                          {{ Number(article?.OFFERT?.Valeur).toFixed(2).toString() }} €
                        </span>
                        <span v-else>
                          {{ Number(article.PrixTotalNetHT).toFixed(2).toString() }} €
                        </span>
                      </td>
                      <td class="text-end">
                        <span v-if="article?.OFFERT">
                          {{ Number(article?.OFFERT?.Valeur).toFixed(2).toString() }} €
                        </span>
                        <span v-else>
                           {{ Number(article.PrixTotalNet).toFixed(2).toString() }} €
                        </span>
                      </td>
                      <td class="text-end">
                        {{ article.LibTVA }}
                      </td>
                     
                    </tr>
                   
                  </tbody>
                </table> -->
              </div>
            </div>
            <div v-if="transfertArticles?.length > 0 && TransfertTVA" class="col-12 col-md-12 col-xl-12 mt-3  mb-3">
              <div class="table-responsive mb-5">
                <DataTable showGridlines stripedRows :value="TransfertTVA">
                  <Column field="Libelle" header="CODE" sortable>
                  </Column>
                  <Column header="TVA" sortable>
                    <template #body="slotProps">
                      <span>
                        {{ Number(slotProps?.data?.ValeurTVA).toFixed(2).toString() }} €
                      </span>
                    </template>
                  </Column>
                  <Column header="HT" sortable>
                    <template #body="slotProps">
                      <span>
                        {{ Number(slotProps?.data?.ValeurHT).toFixed(2).toString() }} €
                      </span>
                    </template>
                  </Column>
                  <Column header="TTC" sortable>
                    <template #body="slotProps">
                      <span>
                        {{ Number(slotProps?.data?.ValeurTTC).toFixed(2).toString() }} €
                      </span>
                    </template>
                  </Column>

                </DataTable>
                <!-- <table class="table table-striped table-hovers caption-top">

                  <thead class="table-dark">
                    <tr class="">
                      <th class="text-start">CODE </th>
                      <th class="text-start">TVA </th>
                      <th class="text-start">HT</th>
                      <th class="text-start">TTC </th>
                    </tr>
                  </thead>
                  <tbody class="">
                    <tr v-for="(tva, indexTVA) in TransfertTVA">
                      <td class="text-start">
                        {{ tva?.Libelle }}
                      </td>
                      <td class="text-start">
                        {{ tva?.ValeurTVA?.toFixed(2)?.toString() }} €
                      </td>
                      <td class="text-start">
                        {{ tva?.ValeurHT?.toFixed(2)?.toString() }} €
                      </td>

                      <td class="text-start">
                        {{ tva?.ValeurTTC?.toFixed(2)?.toString() }} €
                      </td>
                    </tr>
                  </tbody>
                </table> -->
              </div>
            </div>
            <div v-if="arr_commandes?.length > 0 && concatCommandeTypes?.length > 0"
              class="col-12 col-md-12 col-xl-12  mb-3">
              <div class="table-responsive mb-5">
                <DataTable showGridlines stripedRows :value="concatCommandeTypes">
                  <Column field="Libelle" header="DESIGNATION" sortable>
                    <template #body="slotProps">
                      <span v-if="slotProps?.data?.OFFERT">
                        {{ Number(slotProps?.data?.OFFERT?.Quantite).toFixed(0) }}
                        {{ slotProps?.data?.Libelle }}
                        ({{ slotProps?.data?.OFFERT?.Libelle }})
                      </span>
                      <span v-else>
                        {{ Number(slotProps?.data?.QuantiteNet).toFixed(0) }}
                        {{ slotProps?.data?.Libelle }}
                        <span v-if="slotProps?.data?.ANNULATION">
                          ( {{ slotProps?.data?.ANNULATION?.Libelle }})
                        </span>
                      </span>
                    </template>
                  </Column>
                  <Column field="PrixUnitaireTTC" header="Prix UT" sortable>
                    <template #body="slotProps">
                      <span v-if="slotProps?.data?.OFFERT">
                        {{ Number(slotProps?.data?.OFFERT?.Valeur).toFixed(2).toString() }} €
                      </span>
                      <span v-else>
                        {{ Number(slotProps?.data?.PrixUnitaireTTC).toFixed(2).toString() }} €
                      </span>
                    </template>
                  </Column>
                  <Column field="PrixTotalNetHT" header="Prix HT" sortable>
                    <template #body="slotProps">
                      <span v-if="slotProps?.data?.OFFERT">
                        {{ Number(slotProps?.data?.OFFERT?.Valeur).toFixed(2).toString() }} €
                      </span>
                      <span v-else>
                        {{ Number(slotProps?.data?.PrixTotalNetHT).toFixed(2).toString() }} €
                      </span>
                    </template>
                  </Column>
                  <Column field="PrixTotalNet" header="Total" sortable>
                    <template #body="slotProps">
                      <span v-if="slotProps?.data?.OFFERT">
                        {{ Number(slotProps?.data?.OFFERT?.Valeur).toFixed(2).toString() }} €
                      </span>
                      <span v-else>
                        {{ Number(slotProps?.data?.PrixTotalNet).toFixed(2).toString() }} €
                      </span>
                    </template>
                  </Column>
                  <Column header="Total" sortable>
                    <template #body="slotProps">
                      <span>
                        {{ slotProps?.data?.LibTVA }}
                      </span>
                    </template>
                  </Column>

                </DataTable>
                <!-- <table class="table table-striped table-hovers caption-top">
                  <thead class="table-dark">
                    <tr class="">
                      <th>DESIGNATION</th>
                      <th class="text-end">Prix UT</th>
                      <th class="text-end">Prix HT</th>
                      <th class="text-end">Total</th>
                      <th class="text-end">TVA</th>
                    </tr>
                  </thead>
                  <tbody v-if="CommandeArticles?.length > 0" class="">
                    <tr v-for="(article, index_article) in CommandeArticles" :key="index_article">
                      <td>
                        <span v-if="article?.OFFERT">
                          {{ Number(article?.OFFERT?.Quantite).toFixed(0) }}
                          {{ article.Libelle }}
                          ({{ article?.OFFERT?.Libelle }})
                        </span>
                        <span v-else>
                          {{ Number(article.QuantiteNet).toFixed(0) }}
                          {{ article.Libelle }}
                          <span v-if="article?.ANNULATION">
                            ( {{ article?.ANNULATION?.Libelle }})
                          </span>
                        </span>

                      </td>
                      <td class="text-end">
                        <span v-if="article?.OFFERT">
                          {{ Number(article?.OFFERT?.Valeur).toFixed(2).toString() }} €
                        </span>
                        <span v-else>
                          {{ Number(article.PrixUnitaireTTC).toFixed(2).toString() }} €
                        </span>
                      </td>
                      <td class="text-end">
                        <span v-if="article?.OFFERT">
                          {{ Number(article?.OFFERT?.Valeur).toFixed(2).toString() }} €
                        </span>
                        <span v-else>
                          {{ Number(article.PrixTotalNetHT).toFixed(2).toString() }} €
                        </span>
                      </td>
                      <td class="text-end">
                        <span v-if="article?.OFFERT">
                          {{ Number(article?.OFFERT?.Valeur).toFixed(2).toString() }} €
                        </span>
                        <span v-else>
                   {{ Number(article.PrixTotalNet).toFixed(2).toString() }} €
                  </span>
                  </td>
                  <td class="text-end">
                    {{ article.LibTVA }}
                  </td> 
                  </tr>
                  </tbody> 

                  <tbody v-if="CommandeTransfert?.length > 0" class="">
                    <tr v-for="(article, index_article) in CommandeTransfert" :key="index_article">
                      <td>
                        <span v-if="article?.OFFERT">
                          {{ Number(article?.OFFERT?.Quantite).toFixed(0) }}
                          {{ article.Libelle }}
                          ({{ article?.OFFERT?.Libelle }})
                        </span>
                        <span v-else>
                          {{ Number(article.QuantiteNet).toFixed(0) }}
                          {{ article.Libelle }}
                          <span v-if="article?.ANNULATION">
                            ( {{ article?.ANNULATION?.Libelle }})
                          </span>
                        </span>

                      </td>
                      <td class="text-end">
                        <span v-if="article?.OFFERT">
                          {{ Number(article?.OFFERT?.Valeur).toFixed(2).toString() }} €
                        </span>
                        <span v-else>
                          {{ Number(article.PrixUnitaireTTC).toFixed(2).toString() }} €
                        </span>
                      </td>
                      <td class="text-end">
                        <span v-if="article?.OFFERT">
                          {{ Number(article?.OFFERT?.Valeur).toFixed(2).toString() }} €
                        </span>
                        <span v-else>
                          {{ Number(article.PrixTotalNetHT).toFixed(2).toString() }} €
                        </span>
                      </td>
                      <td class="text-end">
                        <span v-if="article?.OFFERT">
                          {{ Number(article?.OFFERT?.Valeur).toFixed(2).toString() }} €
                        </span>
                        <span v-else>
                          {{ Number(article.PrixTotalNet).toFixed(2).toString() }} €
                        </span>
                      </td>
                      <td class="text-end">
                        {{ article.LibTVA }}
                      </td>
                    </tr>
                  </tbody>
                </table>-->
              </div>
            </div>


            <!-- <div v-if="arr_statistiques" class="col-12 col-md-12 col-xl-12 mt-5 mb-3">
              <div class="table-responsive mb-5">
                <table class="table table-striped table-hovers caption-top">
                  <caption>
                    <code> STATISTIQUES : </code>
                  </caption>
                  <thead class="table-dark">
                    <tr class="">
                      <th class="text-start">Libelle</th>
                      <th class="text-start">Famille</th>
                      <th class="text-start">Quantite</th>
                      <th class="text-start">Valeur</th>
                      <th class="text-start">Horodatage</th>
                    </tr>
                  </thead>
                  <tbody class="">
                    <tr v-for="(statistique, index_sts) in arr_statistiques" :key="index_sts">

                      <td class="text-start">
                        {{ statistique?.Libelle }}
                      </td>
                      <td class="text-start">
                        {{ statistique?.LibFamille }}
                      </td>
                      <td class="text-start">
                        {{ statistique?.Quantite }}
                      </td>
                      <td class="text-start">
                        {{ statistique?.Valeur.toFixed(2).toString() }} €
                      </td>
                      <td class="text-start">
                        {{ $dayjs(statistique?.Horodatage)?.utc().format("DD/MM/YYYY HH:mm:ss") }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div> -->
            <div v-if="arr_tvas?.length > 0" class="col-12 col-md-12 col-xl-12  mb-3">
              <div class="table-responsive mb-5">
                <div>
                  <strong> TVA : </strong>
                </div>
                <DataTable showGridlines stripedRows :value="arr_tvas">
                  <Column field="Libelle" header="Libelle" sortable> </Column>
                  <Column field="Horodatage" header="Horodatage" sortable>
                    <template #body="slotProps">
                      <span>
                        {{ $dayjs(slotProps?.data?.Horodatage)?.utc().format("DD/MM/YYYY HH:mm:ss") }}
                      </span>
                    </template>
                  </Column>
                  <Column field="Taux" header="Taux %" sortable>
                    <template #body="slotProps">
                      {{ slotProps?.data?.Taux }} %
                    </template>
                  </Column>
                  <Column field="ValeurHT" header="HT" sortable>
                    <template #body="slotProps">
                      {{ slotProps?.data?.ValeurHT.toFixed(2).toString() }} €
                    </template>
                  </Column>
                  <Column field="ValeurTVA" header="TVA" sortable>
                    <template #body="slotProps">
                      {{ slotProps?.data?.ValeurTVA.toFixed(2).toString() }} €
                    </template>
                  </Column>
                  <Column field="TotalTTC" header="TTC" sortable>
                    <template #body="slotProps">
                      {{ slotProps?.data?.ValeurTTC.toFixed(2).toString() }} €
                    </template>
                  </Column>
                </DataTable>
                <!-- <table class="table table-striped table-hovers caption-top">

                  <thead class="table-dark">
                    <tr class="">
                      <th class="text-start">Libelle</th>
                      <th class="text-start">Horodatage</th>
                      <th class="text-start">Taux %</th>
                      <th class="text-start">HT</th>
                      <th class="text-start">TVA </th>
                      <th class="text-start">TTC </th>
                    </tr>
                  </thead>
                  <tbody class="">
                    <tr v-for="(tva, index_tva) in arr_tvas" :key="index_tva">

                      <td class="text-start">
                        {{ tva?.Libelle }}
                      </td>
                      <td class="text-start">
                        {{ $dayjs(tva?.Horodatage)?.utc().format("DD/MM/YYYY HH:mm:ss") }}
                      </td>
                      <td class="text-start">
                        {{ tva?.Taux }} %
                      </td>
                      <td class="text-start">
                        {{ tva?.ValeurHT.toFixed(2).toString() }} €
                      </td>
                      <td class="text-start">
                        {{ tva?.ValeurTVA.toFixed(2).toString() }} €
                      </td>
                      <td class="text-start">
                        {{ tva?.ValeurTTC.toFixed(2).toString() }} €
                      </td>

                    </tr>
                  </tbody>
                </table> -->
              </div>
            </div>

            <div v-if="arr_reglements?.length > 0" class="col-12 col-md-12 col-xl-12  mb-3">
              <div class="table-responsive mb-5">
                <div>
                  <strong> REGLEMENTS : </strong>
                </div>
                <DataTable showGridlines stripedRows :value="arr_reglements">
                  <Column field="LibFamille" header="Libelle" sortable> </Column>
                  <Column field="LibCaisse" header="LibCaisse" sortable>
                    <template #body="slotProps">
                      <span>
                        {{ slotProps?.data?.LibCaisse }}
                      </span>
                    </template>
                  </Column>
                  <Column field="ValeurUnitaire" header="Valeur Unitaire" sortable>
                    <template #body="slotProps">
                      {{ slotProps?.data?.ValeurUnitaire.toFixed(2).toString() }} €
                    </template>
                  </Column>
                  <Column field="TotalValue" header="Total Value" sortable>
                    <template #body="slotProps">
                      {{ slotProps?.data?.TotalValue.toFixed(2).toString() }} €
                    </template>
                  </Column>
                </DataTable>

                <!-- <table class="table table-striped table-hovers caption-top">
                  <caption>
                    <code> REGLEMENTS : </code>
                  </caption>
                  <thead class="table-dark">
                    <tr class="">
                      <th class="text-start">Libelle</th>
                      <th class="text-start">LibCaisse</th>
                      <th class="text-start">Valeur Unitaire</th>
                      <th class="text-start">Total Value </th>
                    </tr>
                  </thead>
                  <tbody class="">
                    <tr v-for="(reglement, index_reglement) in arr_reglements" :key="index_reglement">

                      <td class="text-start">
                        {{ reglement?.LibFamille }}
                      </td>
                      <td class="text-start">
                        {{ reglement?.LibCaisse }}
                      </td>
                      <td class="text-start">
                        {{ reglement?.ValeurUnitaire.toFixed(2).toString() }} €
                      </td>
                      <td class="text-start">
                        {{ reglement?.TotalValue.toFixed(2).toString() }} €
                      </td>

                    </tr>
                  </tbody>
                </table> -->
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<!-- eslint-disable -->
<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "mongoDATA",

  watch: {},
  data() {
    return {
      exportTo: {
        ticket: {
          columns: [],
          data: [],
          commandes: [],
          statistiques: [],
          reglements: [],
          transfertArticles: [],
          TransfertTVA: {},
          tvas: [],
          fileName: "",
        },
      },
      ticket_mongoDB: [],
      arr_commandes: [],
      arr_statistiques: [],
      arr_reglements: [],
      arr_tvas: [],
      CommandeArticles: [],
      CommandeTransfert: [],
      arr_transferts: [],
      transfertArticles: [],
      TransfertTVA: {},
      concatCommandeTypes: []
    };
  },
  mounted() {
    if (this.isAuthenticated && this.isRole == 'manager')
      this.Get_DetailsTickets(this.$route.params.id);


  },
  created() {
    //this.interval = setInterval(() =>{
    //    this.getDateButton('fiveyear') },20000)
  },
  unmounted() {
    //clearInterval(this.interval)
  },
  methods: {



    ...mapActions(["getDetailsTicket", "getTransfertArticlesByGUUID"]),

    async Get_DetailsTickets(_id) {
      this.ticket_mongoDB = [];


      let result = await this.getDetailsTicket({
        url: "entetes/ticket",
        _id: _id,
      });
      let self = this;
      this.ticket_mongoDB = result[0];
      if (Object.prototype.hasOwnProperty.call(this.ticket_mongoDB, "COMMANDE")) {
        if (this.ticket_mongoDB["COMMANDE"].length === undefined) {

          this.arr_commandes.push(this.ticket_mongoDB["COMMANDE"]);
        } else {
          // multi commande
          Object.keys(this.ticket_mongoDB["COMMANDE"]).forEach(function (key, index) {
            // if (
            //   Object.prototype.hasOwnProperty.call(
            //     self.ticket_mongoDB["COMMANDE"][index],
            //     "ARTICLE"
            //   )
            // ) {
            //   if (
            //     self.ticket_mongoDB["COMMANDE"][index]["ARTICLE"].length === undefined
            //   ) {
            //     // ONE ARTICLE
            //     self.ticket_mongoDB["COMMANDE"][index]["ARTICLE"] = [
            //       self.ticket_mongoDB["COMMANDE"][index]["ARTICLE"],
            //     ];
            //   }
            // }
            self.arr_commandes.push(self.ticket_mongoDB["COMMANDE"][index]);
          });
        }
      }

      if (Object.prototype.hasOwnProperty.call(this.ticket_mongoDB, "STATISTIQUES")) {
        if (this.ticket_mongoDB["STATISTIQUES"].length === undefined) {
          // ONE STATISTIQUES
          this.arr_statistiques.push(this.ticket_mongoDB["STATISTIQUES"]);
        } else {
          // multi STATISTIQUES
          Object.keys(this.ticket_mongoDB["STATISTIQUES"]).forEach(function (key, index) {
            self.arr_statistiques.push(self.ticket_mongoDB["STATISTIQUES"][index]);
          });
        }
      }

      if (Object.prototype.hasOwnProperty.call(this.ticket_mongoDB, "REGLEMENT")) {
        if (this.ticket_mongoDB["REGLEMENT"].length === undefined) {
          // ONE REGLEMENT
          this.arr_reglements.push(this.ticket_mongoDB["REGLEMENT"]);
        } else {
          // multi REGLEMENT
          Object.keys(this.ticket_mongoDB["REGLEMENT"]).forEach(function (key, index) {
            self.arr_reglements.push(self.ticket_mongoDB["REGLEMENT"][index]);
          });
        }
      }

      if (Object.prototype.hasOwnProperty.call(this.ticket_mongoDB, "TVA")) {
        if (this.ticket_mongoDB["TVA"].length === undefined) {
          // ONE TVA
          this.arr_tvas.push(this.ticket_mongoDB["TVA"]);
        } else {
          // multi TVA
          Object.keys(this.ticket_mongoDB["TVA"]).forEach(function (key, index) {
            self.arr_tvas.push(self.ticket_mongoDB["TVA"][index]);
          });
        }
      }

      this.arr_commandes?.forEach(item => {
        if (item?.ARTICLE)
          this.CommandeArticles.push(...item?.ARTICLE);

        if (item?.TRANSFERT)
          this.CommandeTransfert.push(...item?.TRANSFERT);
      });
      this.concatCommandeTypes = this.CommandeArticles.concat(this.CommandeTransfert)

      this.exportTo.ticket.data = [];
      this.exportTo.ticket.data = this.ticket_mongoDB;
      this.exportTo.ticket.commandes = [];
      this.exportTo.ticket.statistiques = [];
      this.exportTo.ticket.commandes = this.arr_commandes;
      this.exportTo.ticket.statistiques = this.arr_statistiques;
      this.exportTo.ticket.reglements = this.arr_reglements;
      this.exportTo.ticket.tvas = this.arr_tvas;
      this.exportTo.ticket.fileName = "TICKET ";
      if (this.arr_commandes.length > 0) {
        this.exportTo.ticket.columns = [];
        this.exportTo.ticket.columns.push("ARTICLE");
        this.exportTo.ticket.columns.push("QUANTITE");
        this.exportTo.ticket.columns.push("PRIX HT");
        this.exportTo.ticket.columns.push("TVA");
        this.exportTo.ticket.columns.push("PRIX TTC");
      }
      /** check if it works  statistiques*/
      if (this.arr_statistiques.length > 0) {
        this.exportTo.ticket.statistiques.columns = [];
        this.exportTo.ticket.statistiques.columns.push("ARTICLE");
        this.exportTo.ticket.statistiques.columns.push("QUANTITE");
        this.exportTo.ticket.statistiques.columns.push("PRIX HT");
        this.exportTo.ticket.statistiques.columns.push("TVA");
        this.exportTo.ticket.statistiques.columns.push("PRIX TTC");
      }
      /** check if it works TVA */
      if (this.arr_tvas.length > 0) {
        this.exportTo.ticket.tvas.columns = [];
        this.exportTo.ticket.tvas.columns.push("ARTICLE");
        this.exportTo.ticket.tvas.columns.push("QUANTITE");
        this.exportTo.ticket.tvas.columns.push("PRIX HT");
        this.exportTo.ticket.tvas.columns.push("TVA");
        this.exportTo.ticket.tvas.columns.push("PRIX TTC");
      }
      /** check if it works TVA */
      if (this.arr_tvas.length > 0) {
        this.exportTo.ticket.reglements.columns = [];
        this.exportTo.ticket.reglements.columns.push("ARTICLE");
        this.exportTo.ticket.reglements.columns.push("QUANTITE");
        this.exportTo.ticket.reglements.columns.push("PRIX HT");
        this.exportTo.ticket.reglements.columns.push("TVA");
        this.exportTo.ticket.reglements.columns.push("PRIX TTC");
      }


      this.arr_transferts = await this.getTransfertArticlesByGUUID({
        url: "entetes/transfert-articles",
        _GUID: this.ticket_mongoDB.GUID
      });

      let ValeurTTC = 0;
      let ValeurHT = 0;
      let ValeurTVA = 0;
      let code = ''
      this.arr_transferts?.forEach(item => {
        this.transfertArticles.push(...item?.ARTICLE);
        item?.ARTICLE?.forEach(itemtva => {
          if (itemtva?.TVA) {
            code = itemtva?.TVA?.Numero + ' ' + itemtva?.TVA?.Libelle
            if (!this.TransfertTVA[code]) {
              this.TransfertTVA[code] = {
                Libelle: code,
                ValeurTTC: itemtva?.TVA?.ValeurTTC,
                ValeurHT: itemtva?.TVA?.ValeurHT,
                ValeurTVA: itemtva?.TVA?.ValeurTVA
              };
            } else {
              this.TransfertTVA[code].ValeurTTC += itemtva?.TVA?.ValeurTTC
              this.TransfertTVA[code].ValeurHT += itemtva?.TVA?.ValeurHT
              this.TransfertTVA[code].ValeurTVA += itemtva?.TVA?.ValeurTVA
            }
          }
        });
      });

      ValeurTTC = 0;
      ValeurHT = 0;
      ValeurTVA = 0;

      if (Object.keys(this.TransfertTVA).length > 0) {
        Object.entries(this.TransfertTVA).forEach(([code, data]) => {
          ValeurTTC += data?.ValeurTTC
          ValeurHT += data?.ValeurHT
          ValeurTVA += data?.ValeurTVA

        })
        this.TransfertTVA['Totaux'] = {
          Libelle: 'Totaux',
          ValeurTTC: ValeurTTC,
          ValeurHT: ValeurHT,
          ValeurTVA: ValeurTVA
        };

        this.exportTo.ticket.TransfertTVA.columns = [];
        this.exportTo.ticket.TransfertTVA.columns.push("CODE");
        this.exportTo.ticket.TransfertTVA.columns.push("TVA");
        this.exportTo.ticket.TransfertTVA.columns.push("PRIX HT");
        this.exportTo.ticket.TransfertTVA.columns.push("PRIX TTC");
        this.exportTo.ticket.TransfertTVA = this.TransfertTVA;
        this.exportTo.ticket.totalTransferts = Math.abs(this.TransfertTVA['Totaux']?.ValeurTTC) || 0;
      }



      /** check if it works TVA */
      if (this.transfertArticles) {
        this.exportTo.ticket.transfertArticles.columns = [];
        this.exportTo.ticket.transfertArticles.columns.push("DESIGNATION");
        this.exportTo.ticket.transfertArticles.columns.push("QUANTITE");
        this.exportTo.ticket.transfertArticles.columns.push("PRIX HT");
        this.exportTo.ticket.transfertArticles.columns.push("PRIX UT");
        this.exportTo.ticket.transfertArticles.columns.push("PRIX TTC");
        this.exportTo.ticket.transfertArticles = this.transfertArticles;

      }





    }
  },

  computed: {
    ...mapGetters({ isRole: "isRole", isAuthenticated: "isAuthenticated", res: "StateRes" }),

    TotalPrice() {
      let Total = 0;
      if (this.arr_commandes) {
        Total = Object.values(this.arr_commandes).reduce((totalPrice, commande) => {
          var TotalPriceGroup = Object.values(commande.ARTICLE).reduce(
            (articlePrice, article) => {
              articlePrice = articlePrice + Number(article.PrixTotalNet);
              return articlePrice;
            },
            0
          );

          totalPrice = TotalPriceGroup + Number(totalPrice);
          return totalPrice;
        }, 0);
        return Total.toFixed(2);
      }
      return 0;
    },
  },
};
</script>
<!-- eslint-disable -->
<style>
.tableClasss {
  height: 400px;
  display: block;
  max-height: 400px;
  overflow: auto;
}

/* .tableClasss thead {
    display: table;
    width: 100%;
    table-layout: fixed;
}

.tableClasss tr {
    display: table;
    width: 100%;
    table-layout: fixed;
}


.info-restautant b {
    display: inline-block;
    width: 90px;
} */

.table thead th,
.datepicker table thead th {
  color: #fff !important;
  font-weight: bold;
}

.border-1-solid {
  border: 1px solid #d9d9d9;
}
</style>
